<template>
	<v-container fluid :class="affichagePortable ? 'pa-0' : ''">
		<v-row class="ma-0" justify="center">
			<v-col cols="12" xs="12" md="8" class="pa-0">
				<v-row align="center" class="ma-0 mb-4 px-4 mt-4" :class="!affichagePortable ? 'mt-8 mb-8' : ''">
					<v-btn fab outlined small @click="annee_precedente" :loading="chargement_annee_precedente" :disabled="!existe_precedente">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" style="cursor: pointer" :key="annee" @click="annee_courante">{{ annee }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="annee_suivante" :loading="chargement_annee_suivante" :disabled="!existe_suivante">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0 mb-2" justify="end" :class="affichagePortable ? 'mr-2' : ''">
					<v-btn-toggle v-model="mode_affichage" mandatory dense>
						<v-btn value="groupe">
							<v-icon color="grey darken-1">mdi-menu</v-icon>
						</v-btn>
						<v-btn value="timeline">
							<v-icon color="grey darken-1">mdi-timeline</v-icon>
						</v-btn>
					</v-btn-toggle>
				</v-row>

				<v-fade-transition mode="out-in">
					<v-expansion-panels v-if="fmpas_courantes.length > 0 && mode_affichage == 'groupe'" flat :key="annee_courante_chargee+'_'+mode_affichage" :class="!affichagePortable ? 'contenu_panels_pc':''">
						<v-expansion-panel v-for="fmpa in fmpas_courantes" :key="fmpa.categorie" class="fmpa_pc">
							<v-expansion-panel-header hide-actions color="indigo lighten-5" class="pr-4">
                                <v-row class="ma-0" align="center">
                                    <v-icon class="mr-5">{{getIconeCategorie(fmpa.categorie)}}</v-icon>
									<div>{{fmpa.categorie}}</div>
                                    <v-spacer/>
									<div v-if="fmpa.duree_requise" class="text-caption">{{getDureeRealiseeListe(fmpa.liste)}} / {{getDureePrevueListe(fmpa.liste)}}</div>
                                    <v-icon class="ml-3" :color="estListeValidee(fmpa.liste) ? 'light-green lighten-1':'red lighten-1'">{{estListeValidee(fmpa.liste) ? 'mdi-check-bold':'mdi-close-thick'}}</v-icon>
                                </v-row>
                            </v-expansion-panel-header>
							<v-expansion-panel-content class="contenu_liste_theme_fmpa" color="grey lighten-5">
								<v-list class="liste_theme_fmpa py-0" v-if="fmpa.liste.length > 0">
                                    <template v-for="(theme, index) in fmpa.liste">
                                        <v-list-item :key="theme.id">
                                            <v-list-item-content>
												<v-row class="ma-0">
													<v-col class="pa-0">
														<div>{{theme.titre}}</div>
														<div class="font-weight-light text-body-2">{{theme.description}}</div>
														<v-row class="ma-0 pl-2" v-for="realisation in theme.realisations" :key="realisation.id" align="center">
															<template v-if="theme.type.duree_requise">
																<div class="text-caption primary--text font-weight-bold">{{formatDuree(realisation.duree_effectuee)}}</div>
																<div class="text-caption primary--text ml-1">le {{formatDate(realisation.date)}}</div>
																<v-chip v-if="realisation.membre_centre.id_centre != centre_courant.id" class="ml-1" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
																<!-- <div v-if="realisation.commentaire" class="text-caption primary--text ml-1">({{realisation.commentaire}})</div> -->
																<!-- <div v-if="realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte" class="text-caption deep-purple--text font-weight-bold ml-1">- hors jour de manoeuvre</div> -->
																<div v-if="realisation.hors_manoeuvre" class="text-caption ml-1 deep-purple--text font-weight-bold"> hors jour de manoeuvre - <span :class="realisation.hors_manoeuvre_compte ? 'light-green--text': 'red--text text--lighten-1'">{{realisation.hors_manoeuvre_compte ? 'pris en compte': 'non pris en compte'}}</span></div>
															</template>
															<template v-else>
																<v-chip label x-small text-color="white" class="font-weight-bold" :color="realisation.est_apte ? 'light-green lighten-1':'red lighten-1'">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</v-chip>
																<div class="text-caption primary--text ml-1">le {{formatDate(realisation.date)}}</div>
																<v-chip v-if="realisation.membre_centre.id_centre != centre_courant.id" class="ml-1" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
																<!-- <div v-if="realisation.commentaire" class="text-caption primary--text ml-1">({{realisation.commentaire}})</div> -->
																<div v-if="realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte" class="text-caption deep-purple--text font-weight-bold ml-1">- hors jour de manoeuvre</div>
																<div v-if="realisation.hors_manoeuvre" class="text-caption ml-1 deep-purple--text font-weight-bold"> hors jour de manoeuvre - <span :class="realisation.hors_manoeuvre_compte ? 'light-green--text': 'red--text text--lighten-1'">{{realisation.hors_manoeuvre_compte ? 'pris en compte': 'non pris en compte'}}</span></div>
															</template>
														</v-row>
													</v-col>
												</v-row>
                                            </v-list-item-content>
											<v-list-item-action>
												<v-row class="ma-0" align="center">
													<div v-if="theme.type.duree_requise" class="text-caption">{{getDureeRealiseeTheme(theme)}} / {{formatDureePrevueTheme(theme)}}</div>
													<v-icon class="ml-3" :color="estThemeValide(theme) || estThemeForce(theme) ? 'light-green lighten-1':'red lighten-1'">{{estThemeForce(theme) ? 'mdi-check-all' : (estThemeValide(theme) ? 'mdi-check':'mdi-close')}}</v-icon>
												</v-row>
											</v-list-item-action>
                                        </v-list-item>
                                        <v-divider :key="'divider'+index"></v-divider>
                                    </template>
								</v-list>
								<v-row v-else class="zone_sans_participants pa-3 my-3" justify="center">
									<div class="text-subtitle-1 font-italic font-weight-light">(aucun thème renseigné)</div>
								</v-row> 
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<div v-else-if="mode_affichage == 'timeline'" :key="annee_courante_chargee+'_'+mode_affichage" :class="!affichagePortable ? 'contenu_panels_pc':''">
						<v-timeline v-if="fmpas_courantes_timeline.length > 0" dense align-top>
							<v-timeline-item v-for="date in fmpas_courantes_timeline" :key="date.date" small color="deep-purple">
								<v-row class="ma-0">
									<v-col class="pa-0">
										<v-chip small label color="deep-purple lighten-2" text-color="white" align="center">{{formatDate(date.date)}}</v-chip>
										<v-card outlined class="mt-1" v-for="realisation in date.detail" :key="realisation.id" :class="affichagePortable ? 'mr-2':''">
											<v-row class="ma-0">
												<v-col class="pa-0 d-flex flex-column" cols="3">
													<v-row class="ma-0 pa-1" justify="center" align="center" :style="'background-color:'+getCouleurCategorie(realisation.theme.type.sigle)">
														<v-icon v-if="!affichagePortable" color="white" class="mr-3">{{getIconeCategorie(realisation.theme.type.sigle)}}</v-icon>
														<div class="white--text font-weight-bold">{{realisation.theme.type.sigle}}</div>
													</v-row>
													<v-row class="ma-0 pa-1" justify="center" align="center">
														<div v-if="realisation.theme.type.duree_requise" class="">{{formatDuree(realisation.duree_effectuee)}}</div>
														<v-chip v-else label x-small text-color="white" class="font-weight-bold" :color="realisation.est_apte ? 'light-green lighten-1':'red lighten-1'">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</v-chip>
														<!-- <div v-else class="text-caption primary--text font-weight-bold">{{realisation.est_apte ? 'APTE' : 'INAPTE'}}</div> -->
													</v-row>
												</v-col>
												<v-col class="pa-1 pl-2">
													<div>{{realisation.theme.titre}}</div>
													<div class="font-weight-light text-body-2">{{realisation.theme.description}}</div>
													<v-chip v-if="realisation.membre_centre.id_centre != centre_courant.id" label x-small outlined>{{realisation.membre_centre.centre.nom}}</v-chip>
													<!-- <div v-if="realisation.commentaire && realisation.theme.type.duree_requise" class="font-weight-light font-italic text-caption">({{realisation.commentaire}})</div> -->
												</v-col>
											</v-row>
										</v-card>
									</v-col>
								</v-row>
							</v-timeline-item>
						</v-timeline>
						<v-row v-else class="ma-0 mt-12" justify="center">
							<div class="text-subtitle-1 font-italic font-weight-light">(aucune FMPA réalisée)</div>
						</v-row>
					</div>
					
					<v-row v-else class="ma-0 mt-12" justify="center" :key="annee_courante_chargee">
						<div class="text-subtitle-1 font-italic font-weight-light">(aucun thème renseigné)</div>
					</v-row>
				</v-fade-transition>
			</v-col>
		</v-row>
    </v-container>
  
</template>

<script>
	import moment from 'moment'
    moment.locale('fr')
	import axios from 'axios'
	import { groupBy } from 'lodash'

	export default {
		
		created() {
			this.$store.commit('majTitrePage', 'Suivi FMPA')
		},
		mounted() {
			this.annee = parseInt(moment().format('YYYY'))
			this.fetchFMPA()
		},
		data() {
			return {
				annee: null,
				annee_courante_chargee: null,	//sert à charger l'annee apres le fetch pour la transition fade au bon moment
				chargement_annee_precedente: false,
				chargement_annee_suivante: false,
				fmpas_courantes: [],
				fmpas_courantes_timeline: [],
				existe_suivante: false,
				existe_precedente: false,
				mode_affichage: 'groupe'
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
		},
		methods: {
			acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			annee_courante() {
				this.annee = parseInt(moment().format('YYYY'))
				this.fetchFMPA()
			},
			annee_precedente() {
				this.annee -= 1
				this.chargement_annee_precedente = true
				this.fetchFMPA()
			},
			annee_suivante() {
				this.annee += 1
				this.chargement_annee_suivante = true
				this.fetchFMPA()
			},
			estListeValidee(liste) {
				//	retourne true si l'ensemble des themes de la liste est validé
				let valide = true
				for (const theme of liste) {
					if(!this.estThemeValide(theme)) {
						valide = false
						break
					}
				}
				return valide
			},
			estThemeForce(theme) {
				//	renvoie true si la validation du thème a été forcée, peu importe dans quel centre
				if(theme.realisations.length == 0) return false
				//return theme.realisations.findIndex(realisation => realisation.theme_force && realisation.membre_centre.id_centre == this.centre_courant.id) > -1
				return theme.realisations.findIndex(realisation => realisation.theme_force) > -1
			},
			estThemeValide(theme) {
				//	retourne true si le theme est validé (duree effectuée >= duree prévue ou apte)
				
				//	si le theme requiert une durée
				if(theme.type.duree_requise) {
					const duree_prevue = moment.duration(theme.duree_prevue).asMinutes()	// durée en minutes
					const duree_realisee = moment.duration(this.getDureeRealiseeTheme(theme)).asMinutes()
					return  duree_realisee >= duree_prevue || this.estThemeForce(theme)
				}
				//	sinon on regarde si la personne a été mise apte sur la dernière réalisation faite
				else {

					//	on ne prend en compte que les réalisation du membr et on prend en compte celles ralisées hors jour de manoeuvre si elles sont comptées
					const liste_filtree = theme.realisations.filter( realisation => !realisation.hors_manoeuvre || (realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte))
					if(liste_filtree.length == 0) return false
					return liste_filtree[liste_filtree.length - 1].est_apte 
				}
				
			},
			fetchFMPA() {
				//	on active les 2 loading si aucun ne l'est (cas du chargement initial)
				if(!this.chargement_annee_precedente && !this.chargement_annee_suivante) {
					this.chargement_annee_suivante = true
					this.chargement_annee_precedente = true
					
                }
                axios.post('api/fmpas/fmpa_membre', { 
                        annee: this.annee, 
						id_membre: this.$store.getters.membreCourant.id,
						id_centre: this.$store.getters.membreCourant.id_centre
                    })
                    .then( (reponse) => {
						if(reponse.data.fmpas) {
							this.annee_courante_chargee = reponse.data.fmpas.annee
							
							const fmpa_groupee = groupBy(reponse.data.fmpas.themes, (theme) => theme.type.sigle)
							let liste_fmpa = []
							for (const [key, fmpa] of Object.entries(fmpa_groupee)) {
								liste_fmpa.push({
									categorie: key,
									duree_requise: fmpa[0].type.duree_requise,
									liste: fmpa
								})
							}

							this.fmpas_courantes = liste_fmpa.sort((a,b) => a.categorie.localeCompare(b.categorie))

							//	format pour FMPA affiché en timeline
							const fmpa_timeline_groupee = groupBy(reponse.data.fmpa_timeline, (realisation) => realisation.date)
							let liste_fmpa_timeline = []
							for (const [key, realisation] of Object.entries(fmpa_timeline_groupee)) {
								liste_fmpa_timeline.push({
									date: key,
									detail: realisation
								})
							}
							this.fmpas_courantes_timeline = liste_fmpa_timeline

						}
						else {
							this.fmpas_courantes = []
							this.fmpas_courantes_timeline = []
						}
						this.existe_suivante = reponse.data.a_suivante
						this.existe_precedente = reponse.data.a_precedente
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des FMPA');
                    })
                    .then(() => {
						this.chargement_annee_suivante = false
						this.chargement_annee_precedente = false
                    })
			},
			formatDate(date) {
				if(!date) return ''
				return moment(date).format('DD/MM/YYYY')
			},
			formatDuree(duree) {
				const duree_minutes = moment.duration(duree).asMinutes()
				const nbre_heures = Math.floor(duree_minutes / 60)
				const nbre_minutes = duree_minutes % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
			},
			formatDureePrevueTheme(theme) {
				const somme = moment.duration(theme.duree_prevue).asMinutes()
				
				const nbre_heures = Math.floor(somme / 60)
				const nbre_minutes = somme % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getCouleurCategorie(categorie) {
				let couleur = '#9E9E9E' 	//grey
				switch (categorie) {
					case 'DIV':
						couleur = '#AFB42B'
						break;
					case 'INC':
						couleur = '#FFA000'
						break;
					case 'SR':
						couleur = '#3949AB'
						break;
					case 'SSUAP':
						couleur = '#D32F2F'
						break;
					default:

						break;
				}
				return couleur
			},
			getDureePrevueListe(liste) {
				//	retoure la durée totale prévue des éléments de la liste
				let somme = 0   //  nbre de minutes
                for (const theme of liste) {
                    if(theme.duree_prevue) {   
                        somme = somme + moment.duration(theme.duree_prevue).asMinutes()
                    }
                }
                const nbre_heures = Math.floor(somme / 60)
				const nbre_minutes = somme % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
                //return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getDureeRealiseeListe(liste) {
				//	retourne la somme des durées réalisées
				let somme = 0
				for (const theme of liste) {
					somme = somme + moment.duration(this.getDureeRealiseeTheme(theme)).asMinutes()
				}
				const nbre_heures = Math.floor(somme / 60)
                const nbre_minutes = somme % 60
				
				return parseInt(nbre_heures) + ':' + nbre_minutes.toString().padStart(2, '0')
				//return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')

			},
			getDureeRealiseeTheme(theme) {
				//	retourne la durée réalisée du theme

				//	on ne prend en compte que les réalisation du membre et on prend en compte celles ralisées hors jour de manoeuvre si elles sont comptées
				const liste_filtree = theme.realisations.filter( realisation => !realisation.hors_manoeuvre || (realisation.hors_manoeuvre && realisation.hors_manoeuvre_compte))
				let somme = 0
				for (const realisation of liste_filtree) {
					somme = somme + moment.duration(realisation.duree_effectuee).asMinutes()
				}
				const nbre_heures = Math.floor(somme / 60)
                const nbre_minutes = somme % 60
				
				return parseInt(nbre_heures) + ':' + nbre_minutes.toString().padStart(2, '0')
				//return nbre_heures.toString().padStart(2, '0') + ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getIconeCategorie(categorie) {
				let icone = ''
				switch (categorie) {
					case 'DIV':
						icone = 'mdi-traffic-cone'
						break;
					case 'INC':
						icone = 'mdi-fire'
						break;
					case 'SR':
						icone = 'mdi-car-sports'
						break;
					case 'SSUAP':
						icone = 'mdi-hospital-box'
						break;
					case 'CP':
						icone = 'mdi-fire-extinguisher'
						break;
					default:

						break;
				}
				return icone
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },
		},
		watch: {
            centre_courant() {
                
                //  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces suivi fmpa')) {
                    this.$router.push('/')
                }
                else {
                    this.fetchFMPA()
                }
            }
        }
	}
</script>

<style scoped>

.contenu_panels_pc {
	max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
}

.liste_theme_fmpa {
    background-color: transparent;
}

</style>

<style>

.contenu_liste_theme_fmpa .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    
}

.fmpa_pc {
	border-bottom: 1px solid #E0E0E0 !important;
}


</style>